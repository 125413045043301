<template>
  <div class="synthesis-style">
    <div class="outbox center-center">
      <el-card class="box-card" v-loading="isloading">
        <div class="box1 between-center">
          <img src="@/assets/img/img39.png" alt="" class="b1-t3" />
          <div class="b1-t2">True NFT</div>
          <img src="@/assets/img/img32.png" alt="" @click="goAhead('/syntheticlist')" class="b1-t1" />
        </div>
        <van-divider>{{$t('text.t280')}}</van-divider>
        <div class="box2 between-center">
          <div class="start-center">
            <img src="@/assets/img/img43.png" alt="" class="b2-i1" />
            <div class="b2-t1">USDT</div>
          </div>
          <div class="b2-t2">{{$t('text.t133')}}：{{user.ompusdt}} </div>
        </div>
        <div class="box3 between-center b3-mb20">
          <input readonly class="b3-input" v-model="chip_num" />
          <!-- <div class="b3-t1">MAX</div> -->
        </div>
        <div class="box4 center-center">
          <img src="@/assets/img/img35.png" alt="" class="box4-i" />
        </div>
        <div class="box2 between-center">
          <div class="start-center">
            <img src="@/assets/img/img40.png" alt="" class="b2-i1" />
            <div class="b2-t1">True NFT</div>
          </div>
        </div>
        <div class="box3 between-center b3-mb15">
          <input type="number" class="b3-input" :placeholder="$t('text.t337')" v-model="true_num" />
        </div>
        <div class="box5">{{$t('text.t134')}}：1 True NFT ≈ {{u_price}} USDT OMT</div>
        <div class="box6 center-center" @click="exchangeTrue">{{$t('text.t9')}}</div>
      </el-card>
    </div>
    <div class="box8">
      <div class="b8-t1">True NFT {{$t('text.t102')}}</div>
      <div class="b8-t2 between-center">
        <div>{{$t('text.t104')}}</div>
        <div class="end-center">
          <div>{{config.true?.substr(0,11)}}...{{config.true?.substr(config.true.length-10)}}</div>
          <img src="@/assets/img/copy.png" @click="copy(config.true)" alt="" class="b8-imgcopy" />
        </div>
      </div>
      <div class="b8-t2 between-center">
        <div>{{$t('text.t194')}}</div>
        <div>TRUE NFT</div>
      </div>
      <div class="b8-t2 between-center">
        <div>{{$t('text.t106')}}</div>
        <div>ERC-721</div>
      </div>
      <div class="b8-t2 between-center">
        <div>{{$t('text.t107')}}</div>
        <div>OMP</div>
      </div>
    </div>
    <div class="box8">
      <div class="b8-t1">True NFT {{$t('text.t195')}}</div>
      <div class="b8-t2 b8-t3">
        {{$t('text.t232')}}
      </div>
    </div>
    <div class="box9"></div>
  </div>
</template>
<script>
import {mapActions,mapState} from 'vuex'
import BigNumber from "bignumber.js";
export default {
  components: {
  },
  data() {
    return {
      true_num:'',
      isloading:false,
      u_price:'',
    };
  },
  created(){
    this.getUser()
  },
  computed: {
    ...mapState(["user","config","language"]),
    chip_num(){
      return this.true_num * this.u_price
    }
  },
  mounted() {
    let timer = null;
    timer = setInterval(() => {
      if(typeof window.ethereum !== 'undefined' && JSON.stringify(this.config) != "{}"){
        clearInterval(timer)
        this.getBili()
      }
    }, 200);
  },
  methods: {
    ...mapActions(["getUser"]),
    async getBili(){
      const web3 = new this.Web3(ethereum);
      const contract_to = this.config.synthesis_contract;
      const abi_to = JSON.parse(this.config.synthesis_abi);
			const nft_contract = new web3.eth.Contract(abi_to, contract_to);
      console.log(nft_contract);
      const composePrice = await nft_contract.methods.getComposePrice().call()
      console.log(composePrice,web3.utils.fromWei(composePrice));
      this.u_price = web3.utils.fromWei(composePrice)
    },
    async exchangeTrue(){
      if(this.true_num.indexOf('.') !== -1){
        this.$toast(this.$t('text.t282'))
        return
      }
      try {
        this.isloading = true
        let arg = {
          number:this.true_num,
          language:this.language
        }
        const res = await this.$http.post('/destroy/destroyOrder',arg)
        console.log(res);
        const {destroy,get,address,orderno} = res.data
        await this.checkAllowance(destroy)
        this.compoundNFT(get,address,orderno)
      } catch (error) {
        console.log(error);
        this.isloading = false
      }
    },
    async checkAllowance(destroy){
      const user_address = this.$local.get('shop:account');
      console.log(user_address);
			const web3 = new this.Web3(ethereum);
			let f_max_num = new BigNumber(2).pow(256).minus(1);  //最大值
			const max_num = f_max_num.toString(16)
			console.log(max_num);
			const contract_from = this.config.omp_usdt_contract; // 被授权的合约地址
			const abi_from = JSON.parse(this.config.omp_usdt_abi);
			const contract_to = this.config.synthesis_contract; // 授权的合约地址
			
			const contract_proto = new web3.eth.Contract(abi_from, contract_from,{from:user_address});
			const isAllowance = await contract_proto.methods.allowance(user_address,contract_to).call();
			console.log(isAllowance);
			console.log(new BigNumber(isAllowance).gte(web3.utils.toWei(destroy)));
			if(new BigNumber(isAllowance).gte(web3.utils.toWei(destroy))){
				return true
			} else{
				const result1 = await contract_proto.methods.approve(contract_to,'0x'+max_num).send()
				console.log(result1);
        return true
			}
		},
    async compoundNFT(get,address,orderno){
			const web3 = new this.Web3(ethereum);
      const contract_to = this.config.synthesis_contract;
      const abi_to = JSON.parse(this.config.synthesis_abi);
			const nft_contract = new web3.eth.Contract(abi_to, contract_to,{from:address});
      console.log(nft_contract);
      nft_contract.methods.compose(Number(get)).send().on('transactionHash',async (hash)=>{
				const rest = await this.sendHash(hash,orderno,0)
        this.tmsg = ''
        this.tmsg = rest.msg
			}).on('receipt',(receipt)=>{
        this.isloading = false
				this.$toast(this.tmsg)
        this.getUser()
			}).on('error', (error, receipt)=>{
				console.log(error,receipt);
        this.isloading = false
			})
		},
    async sendHash(hash,orderno,type){
			const arg = {
				hash,
				orderno,
				type,
				language:this.language
			}
			const rest = await this.$http.post('/destroy/addHash',arg)
      return rest
		},
  },
};
</script>

<style lang="less">
.synthesis-style {
  .outbox {
    padding: 15px 0 25px;
    .box-card {
      width: 350px;
      border-radius: 20px;
      .van-divider {
        font-size: 9px;
        margin: 11px auto 26px;
      }
      .box1 {
        padding: 0 20px;
        .b1-t1 {
          width: 22px;
          height: auto;
        }
        .b1-t2 {
          font-size: 24px;
          font-weight: bold;
          color: #323232;
        }
        .b1-t3 {
          width: auto;
          height: 20px;
          opacity: 0;
        }
      }
      .box2 {
        margin-bottom: 12px;
        .b2-i1 {
          width: 20px;
          height: auto;
          margin-right: 10px;
        }
        .b2-t1 {
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .b2-t2 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
      .box3 {
        height: 50px;
        background: #f4f4f4;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        padding: 0 20px;
        .b3-input {
          width: 80%;
          height: 26px;
          border: none;
          background-color: transparent;
          font-size: 12px;
          font-weight: 600;
          color: #000 !important;
        }
        .b3-t1 {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
      }
      .b3-mb20 {
        margin-bottom: 20px;
      }
      .b3-mb15 {
        margin-bottom: 15px;
      }
      .b3-mb33 {
        margin-bottom: 33px;
      }
      .box4 {
        margin-bottom: 21px;
        .box4-i {
          width: 52px;
          height: 52px;
        }
      }
      .box5 {
        margin-bottom: 38px;
        font-size: 12px;
        font-weight: 500;
        color: #323232;
      }
      .box6 {
        width: 304px;
        height: 45px;
        background: #2b2b2b;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        margin: 0 auto 15px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
      .box7 {
        width: 304px;
        height: 45px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #2b2b2b;
        margin: 0 auto 30px;
        font-size: 18px;
        font-weight: bold;
        color: #323232;
        border: 1px solid #323232;
        .b7-i1 {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .box8 {
    width: 300px;
    background: #f4f4f4;
    border-radius: 10px;
    padding: 25px 22px;
    margin: 0 auto 20px;
    .b8-t1 {
      text-align: center;
      margin-bottom: 24px;
      font-size: 15px;
      font-weight: bold;
      color: #323232;
    }
    .b8-imgcopy{
      width: 13px;
      height: 13px;
      margin-left: 2px;
    }
    .b8-t2 {
      font-size: 12px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 25px;
    }
    .b8-t2:last-of-type{
      margin-bottom: 0;
    }
    .b8-t3{
      line-height: 1.5;
      font-weight: 500;
    }
  }
  .box9{
    height: 10px;
  }
}
</style>